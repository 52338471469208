<template>
  <RouterLink
    v-if="hasPermissionToUpdate"
    :to="{ name: 'update-broadcast-page', params: { id: props.resource.relationships?.broadcast_page?.id } }"
    class="bg-gradient-to-br justify-center items-center duration-300 whitespace-nowrap inline-flex py-2 px-5
      font-semibold rounded-full shadow-lg shadow-theme-500/10 from-theme-500 to-theme-700 hover:from-theme-600
    hover:to-theme-800 text-white text-sm"
  >
    {{ t('broadcast.ui.buttons.broadcast_page') }}
  </RouterLink>

  <RouterLink
    v-if="!broadcastPageExist && hasPermissionToCreate"
    :to="{ name: 'update-broadcast-page', params: { id: props.resource.relationships?.broadcast_page?.id } }"
    class="bg-gradient-to-br justify-center items-center duration-300 whitespace-nowrap inline-flex py-2 px-5
      font-semibold rounded-full shadow-lg shadow-theme-500/10 from-theme-500 to-theme-700 hover:from-theme-600
    hover:to-theme-800 text-white text-sm"
  >
    {{ t('broadcast.ui.buttons.create_broadcast_page') }}
  </RouterLink>
</template>

<script setup>
import {
  computed,
} from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  // Broadcast Page
  resource: {
    type: Object,
    required: true,
  },
})

const broadcastPageExist = computed(() => (
  props.resource.relationships?.broadcast_page !== null
))

const hasPermissionToUpdate = computed(() => props.resource.relationships?.broadcast_page?.authorizations?.update)
const hasPermissionToCreate = computed(() => true)
</script>
